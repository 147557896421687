<route>
{
  "meta": {
    "permission": [
      "inventory.view_movement"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <ValidationProvider vid="code" :name="$t('code')" v-slot="{ errors }">
          <v-text-field
            v-model="form.code"
            outlined
            :error-messages="errors[0]"
            :label="$t('code')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="movement_type"
          :name="$t('movement_type')"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.movement_type"
            outlined
            :error-messages="errors[0]"
            :label="$t('movement_type')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <i-table
      :title="$t('movementsReport')"
      :headers="headers"
      app="inventory.movement"
      api="movement"
      :opt="{
        code: this.form.code,
        movement_type: this.form.movement_type
      }"
      dontEdit
      dontRemove
      dontCreate
      dontSearch
    >
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $t('movementsReport') })"
          color="text-dark"
          @click="getMovementsExcel()"
        >
        </i-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      form: {
        code: '',
        movement_type: ''
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('code', 2),
          value: 'own_prod'
        },
        {
          text: this.$tc('movement_type', 2),
          value: 'movement_type'
        },
        {
          text: this.$tc('quantity', 2),
          value: 'quantity'
        },
        {
          text: this.$t('updated_date'),
          value: 'updated_date'
        },
        {
          text: this.$t('updated_user'),
          value: 'own_user'
        }
      ]
    }
  },
  methods: {
    getMovementsExcel() {
      this.downloadFile({
        api: 'movement',
        method: 'excel',
        filename: 'InventoryMovements',
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params:
            '?code=' +
            this.form.code +
            '&movement_type=' +
            this.form.movement_type
        }
      })
    }
  }
}
</script>
